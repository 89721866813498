import React, { useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Selector from '../../../Main/selectors';
import { updateBasket } from '../../../Basket/actions';
import HeaderLine from '../../../../common/HeaderLine';
import Modal from '../../../../common/Modal';
import './styles.scss';


const Sparepart = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { item, goToArchive, onDelete, onAccept, accepted } = props;
  const { article, name, brand, available, price } = item;
  const [count, setCount] = useState(item.count || 1);
  const [notAuthModal, toogleNotAuthModal] = useState(false);
  const [zoomUrlModal, setZoomUrlModal] = useState('');
  const user = useSelector(state => Selector(state, 'user'));
  
  const onChangeCountHandler = (e) => {
    const count = e.target.value;
    if (available.available < count) return;
    setCount(count);
    dispatch(updateBasket({ ...item,  count }));
  };
  const addBasketHandler = useCallback(() => { 
    user.id ? dispatch(updateBasket({ ...item,  count })) : toogleNotAuthModal(true);
  }, [dispatch, item, count, user]);

  const onAcceptHandler = useCallback((direction) => onAccept(item, direction), [item, onAccept]);

  const onDeleteHandler = useCallback(() => onDelete(item), [item, onDelete]);

  const goToRequest = useCallback(() => { toogleNotAuthModal(false); history.push(`/?r=${Math.random()}#request`); }, [history]);


  return (
    <>
      <div className='SparepartSlab'>
        <div className='colum'>
          <div className='subtitle'>
            <span>Артикул: </span>
            {article}
          </div>
          <div
            className='image'
            style={ { backgroundImage: `url(/image/${item.articleSearch})`, cursor: 'pointer' } }
            onClick={() => setZoomUrlModal(item.articleSearch)}
          >
          </div>
        </div>
        <div className='colum'>
          <HeaderLine title="Описание" type='div' />
          <div className='attrRow'>
            <span>Наименование:</span>
            {name}
          </div>
          <div className='attrRow'>
            <span>Производитель:</span>
            {brand && brand.name}
          </div>
          <div className='attrRow'>
            <span>Артикул:</span>
            {article}
          </div>
          <div className='attrRow'>
            <span>Наличие на складе (шт.):</span>
            {available ? `${available.available > 5 ? 'более 5' : available.available}` : (<div className='highlight'>Нет в наличии</div>)}
          </div>
          <div className='pushRow'></div>
          <div>
            {goToArchive && <button className="default" onClick={goToArchive}>Архив заказов</button>}
          </div>
        </div>
        <div className='colum'>
          <HeaderLine title="Стоимость" type='div'/>
          <div className='attrRow'>
            <span>Количество:</span>
            <input type='number' value={count} onChange={onChangeCountHandler} max={available ? available.available : 0} min={0}/>
            {'шт'}
          </div>
          <div className='attrRow'>
            <span>Цена оптовая:</span>
            {`${price} руб.`}
          </div>
          <HeaderLine />
          <div className='attrRow'>
            <span>Итоговая сумма:</span>
            <div className='highlight'>{ `${count * price} руб.` }</div>
          </div>
          <div className='pushRow'></div>
          <div>
            {onDelete && onAccept
              ? (
                <div className='row'>
                  <button
                    className={`default rightOffset ${accepted ? 'accent': ''}`}
                    onClick={() => accepted ? onAcceptHandler(false) : onAcceptHandler(true)}
                  >
                    {accepted ? 'Подтвержден' : 'Подтвердить'}
                  </button>
                  <button className="accent" onClick={onDeleteHandler}>Удалить</button>
                </div>
              )
              : (<button className="default" disabled={!(available && available.available)} onClick={addBasketHandler}>В корзину</button>)
            }
          </div>
        </div>
      </div>

      {notAuthModal &&
        <Modal
          open
          title={<>Для возможности покупок вам необходимо <span className="highlight">авторизоваться!</span></>}
          content={<>
            <p>Если вы являетесь нашим клиентом и у вас нет логина и пароля, свяжитесь с вашим персональным менеджером</p>
            <p>
              Если вы не являетесь нашим клиентом <a className="link" onClick={goToRequest}>отправьте заявку</a> 
            </p>

          </>}
          onClose={() => toogleNotAuthModal(false)}
        />
      }
      {zoomUrlModal &&
        <Modal
          open
          title={''}
          className="zoomImageModal"
          content={
            <div style={{ width: '800px'}}>
              <img width={'100%'} src={`/image/${item.articleSearch}`} />
            </div>
          }
          onClose={() => setZoomUrlModal('')}
        />
      }
    </>
  );
};


export default Sparepart;


